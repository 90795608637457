import React, { ReactElement } from "react";
import { StaticTechPattern } from "../TechPattern/TechPattern";
import { ReactComponent as TechTextContainer } from "../../../assets/svg/tech-text-container-no-sides.svg";
import styles from "./FlipCard.module.scss";

interface Props {
  backTitle: string;
  backSubTitle: string;
  cardImage: string;
  children: ReactElement;
}

interface OrganizationProps {
  cardImage: string;
  children: ReactElement;
  isMobile: boolean;
}

const FlipCard = ({ backTitle, backSubTitle, cardImage, children }: Props) => {
  const {
    FlipCardContainer,
    FlipCard,
    CardFront,
    CardBack,
    BackContainer,
    BottomTitle,
  } = styles;

  return (
    <>
      <div className={FlipCardContainer}>
        <div className={FlipCard}>
          <div className={CardFront}>
            <img src={cardImage} alt="box" />
          </div>
          <div className={CardBack}>
            <StaticTechPattern />
            <div className={BackContainer}>
              <h4 className="dont-animate">{backTitle}</h4>
              <h3 className="dont-animate">{backSubTitle}</h3>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <h5 className={`${BottomTitle} dont-animate`}>
        {backTitle}
        <span>{backSubTitle}</span>
      </h5>
    </>
  );
};

export const OrganizationFlipCard = ({
  cardImage,
  children,
  isMobile,
}: OrganizationProps) => {
  const {
    OrganizationFlipCard,
    FlipCardContainer,
    FlipCard,
    CardFront,
    CardBack,
    OrganizationMobileCard,
  } = styles;

  return (
    <>
      {isMobile ? (
        <div className={OrganizationMobileCard}>
          <img src={cardImage} alt="box" />
          <p>{children}</p>
        </div>
      ) : (
        <div className={`${FlipCardContainer} ${OrganizationFlipCard}`}>
          <div className={FlipCard}>
            <div className={CardFront}>
              <img src={cardImage} alt="box" />
            </div>
            <div className={CardBack}>
              <TechTextContainer />
              <p className="dont-animate">{children}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FlipCard;
