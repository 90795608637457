import { gsap } from "gsap";

const random = (min: number, max: number) => {
  const delta = max - min;
  return (direction = 1) => (min + delta * Math.random()) * direction;
};

const randomX = random(10, 20);
const randomY = random(10, 20);
const randomTime = random(3, 5);

export const moveX = (target: HTMLElement, direction: number) => {
  gsap.to(target, {
    x: randomX(direction),
    ease: "sine.inOut",
    onComplete: moveX,
    onCompleteParams: [target, direction * -1],
    duration: randomTime(),
  });
};

export const moveY = (target: HTMLElement, direction: number) => {
  gsap.to(target, {
    y: randomY(direction),
    ease: "sine.inOut",
    onComplete: moveY,
    onCompleteParams: [target, direction * -1],
    duration: randomTime(),
  });
};

export const scrollTop = (height?: any) => {
  if(height){
    window.scrollTo(0, height);
  }else{
    window.scrollTo(0, 0);
  }
};

export const changeNavTheme = (
  entries: IntersectionObserverEntry[],
  setNavTheme: (value: React.SetStateAction<"light" | "dark">) => void
) => {
  entries.forEach((entry) => {
    // console.log(entry);
    // if (entry.isIntersecting && entry.intersectionRatio > 0) {
    if (entry.isIntersecting) {
      if (
        entry.target.classList.contains("bg-light") ||
        entry.target.classList.contains("secondary")
      ) {
        return setNavTheme("dark");
      }

      if (
        entry.target.classList.contains("bg-primary") ||
        entry.target.classList.contains("primary")
      ) {
        return setNavTheme("light");
      }
    }
  });
};
