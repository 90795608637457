import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Menu from "./components/Menu/Menu";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Home from "./Home";
import Jobs from "./Jobs";
import ScrollToTop from "./helpers/scrollToTop";
// import useTextAnimations from "./hooks/useTextAnimations";
import { TimelineFunction, Theme } from "./interfaces/types";

// i18n
import { useTranslation } from "react-i18next";

const App = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { t } = useTranslation();
  const mainContainer = useRef(null);
  const openMenu = useRef<TimelineFunction>();
  const closeMenu = useRef<TimelineFunction>();
  const [navTheme, setNavTheme] = useState<Theme>("light");
  const [width, setWidth] = useState<number>();
  const [runAnimation, setRunAnimation] = useState(true);
  const [offsetY, setOffsetY] = useState(0);
  const [finishedIntro, setFinishedIntro] = useState(false);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  // useTextAnimations();

  // Text animation

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const appHeightWidth = () => {
    // console.log("run resize -------");
    const doc = document.documentElement;
    doc.style.setProperty("--window-height", `${window.innerHeight}px`);
    doc.style.setProperty("--window-width", `${window.innerWidth}px`);
  };

  useLayoutEffect(() => {
    const isMobile = window.innerWidth < 768;

    if (!isMobile) {
      window.addEventListener("resize", appHeightWidth);
    }

    appHeightWidth();
  }, []);

  useEffect(() => {
    // Scroll Listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const finishedIntroHandler = () => {
    setTimeout(() => {
      setFinishedIntro(true);
    }, 500);
  };

  return (
    <div ref={mainContainer}>
      <BrowserRouter>
        <ScrollToTop />
        {/* <Menu openMenu={openMenu} closeMenu={closeMenu} /> */}
        <Menu
          offsetY={0}
          theme="light"
          openMenu={openMenu}
          closeMenu={closeMenu}
          t={t}
        />
        <Navigation
          width={width}
          offsetY={offsetY}
          theme={navTheme}
          t={t}
          showMenu={() => !!openMenu.current && openMenu.current()}
          finishedIntro={finishedIntro}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setNavTheme={setNavTheme}
                t={t}
                gsap={gsap}
                width={width}
                runAnimation={runAnimation}
                setRunAnimation={setRunAnimation}
                offsetY={offsetY}
                finishedIntro={finishedIntroHandler}
              />
            }
          />
          <Route
            path="jobs"
            element={
              <Jobs
                width={width}
                t={t}
                setNavTheme={setNavTheme}
                offsetY={offsetY}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
