import HoverTitle from "../ui/HoverTitle/HoverTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoColor from "../../assets/svg/logo-color.svg";
import { Translation } from "../../interfaces/types";

import styles from "./HoverAlmaTitles.module.scss";

interface Props {
  t: Translation;
  width: number | undefined;
}

const HoverAlmaTitles = ({ t, width }: Props) => {
  const { HoverAlmaTitles } = styles;

  const isMobile = !!width && width < 768;

  return (
    <Row className={HoverAlmaTitles}>
      <Col sm={true}>
        <HoverTitle
          isMobile={isMobile}
          title={t("home.what-we-do.hover-alma-titles.title1")}
          text={t("home.what-we-do.hover-alma-titles.text1")}
        />
      </Col>
      {!isMobile && (
        <Col sm={true}>
          <img src={logoColor} alt="Alma Global" />
        </Col>
      )}
      <Col sm={true}>
        <HoverTitle
          isMobile={isMobile}
          title={t("home.what-we-do.hover-alma-titles.title2")}
          text={t("home.what-we-do.hover-alma-titles.text2")}
        />
      </Col>
    </Row>
  );
};

export default HoverAlmaTitles;
