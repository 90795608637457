import { useRef, useLayoutEffect } from "react";
import techPattern1 from "../../../assets/svg/tech-pattern-1.svg";
import techPattern2 from "../../../assets/svg/tech-pattern-2.svg";
import techPattern3 from "../../../assets/svg/tech-pattern-3.svg";
import techPattern4 from "../../../assets/svg/tech-pattern-4.svg";
import techPattern5 from "../../../assets/svg/tech-pattern-5.svg";
import { moveX, moveY } from "../../../helpers";

import styles from "./TechPattern.module.scss";

interface TechPatternProps {
  position: "bottomRight" | "topLeft";
  opacity?: "half";
}

interface SmallTechPatternProps {
  position: "left" | "right" | "leftCenter" | "farLeft";
}

export const SmallTechPattern = ({ position }: SmallTechPatternProps) => {
  const { smallTechPattern } = styles;
  const patternFourRef = useRef<HTMLImageElement>(null);
  const patternFiveRef = useRef<HTMLImageElement>(null);

  const patterns = [
    { alt: "Tech pattern 4", image: techPattern4, ref: patternFourRef },
    { alt: "Tech pattern 5", image: techPattern5, ref: patternFiveRef },
  ];

  useLayoutEffect(() => {
    patterns.forEach(({ ref }) => {
      if (ref?.current) {
        moveX(ref.current, 1);
        moveY(ref.current, -1);
      }
    });
  }, []);

  return (
    <aside className={smallTechPattern}>
      {patterns.map(({ alt, image, ref }) => {
        return (
          <img
            className={styles[position]}
            ref={ref}
            key={alt}
            src={image}
            alt={alt}
          />
        );
      })}
    </aside>
  );
};

export const TechPattern = ({ position, opacity }: TechPatternProps) => {
  const { techPattern } = styles;
  const patternOneRef = useRef<HTMLImageElement>(null);
  const patternTwoRef = useRef<HTMLImageElement>(null);
  const patternThreeRef = useRef<HTMLImageElement>(null);

  const patterns = [
    { alt: "Tech pattern 1", image: techPattern1, ref: patternOneRef },
    { alt: "Tech pattern 2", image: techPattern2, ref: patternTwoRef },
    { alt: "Tech pattern 3", image: techPattern3, ref: patternThreeRef },
  ];

  useLayoutEffect(() => {
    patterns.forEach(({ ref }) => {
      if (ref?.current) {
        moveX(ref.current, 1);
        moveY(ref.current, -1);
      }
    });
  }, []);

  return (
    <aside className={techPattern}>
      {patterns.map(({ alt, image, ref }) => {
        return (
          <img
            ref={ref}
            key={alt}
            className={`${styles[position]} ${
              opacity ? styles[opacity] : null
            }`}
            src={image}
            alt={alt}
          />
        );
      })}
    </aside>
  );
};

export const StaticTechPattern = () => {
  const { staticTechPattern } = styles;

  const patterns = [
    { alt: "Tech pattern 1", image: techPattern1 },
    { alt: "Tech pattern 2", image: techPattern2 },
  ];

  return (
    <aside className={staticTechPattern}>
      {patterns.map(({ alt, image }) => {
        return <img key={alt} src={image} alt={alt} />;
      })}
    </aside>
  );
};

export default TechPattern;
