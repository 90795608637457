import React, { useEffect, useState, MouseEventHandler } from "react";
import Section from "./components/Section/Section";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SmallTechPattern } from "./components/ui/TechPattern/TechPattern";
import HexaLink from "./components/ui/HexaLink/HexaLink";
import Separator from "./components/ui/Separator/Separator";
import Form from "./components/Form/Form";
import useObserver from "./hooks/useObserver";
import { changeNavTheme } from "./helpers";
import ScrollTopArrow from "./components/ui/ScrollTopArrow/ScrollTopArrow";
import { scrollTop } from "./helpers/index";
import Button from "./components/ui/Button/Button";
import { useNavigate } from "react-router-dom";
import { Translation } from "./interfaces/types";
import useTextAnimations from "./hooks/useTextAnimations";

interface Props {
  t: Translation;
  setNavTheme: (value: React.SetStateAction<"light" | "dark">) => void;
  offsetY: number;
  width: number | undefined;
}

const Searches = ({ t, setNavTheme, offsetY, width }: Props) => {
  const [observer, setElements, entries] = useObserver();
  const [showApply, setShowApply] = useState(false);
  useTextAnimations(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (Array.isArray(entries)) changeNavTheme(entries, setNavTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries, observer]);

  useEffect(() => {
    if (typeof setElements === "function") {
      const sections = document.getElementsByClassName("section");
      setElements(Array.from(sections));
    }
  }, [setElements]);

  const backClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
    // e.preventDefault();
    const href = e.currentTarget.href.split("#")[1];

    navigate(`/#${href}`);
  };

  const isMobile = !!width && width < 768;

  return (
    <main>
      <Section id="searches">
        <>
          <h1>{t("jobs.careers.title")}</h1>
          <Col sm={10}>
            <h2 className="mb-5">{t("jobs.careers.text1")}</h2>
          </Col>
          <Row className="position-relative">
            <Col sm={10}>
              <h3 className="mb-5">{t("jobs.careers.text2")}</h3>
            </Col>
            {!isMobile && (
              <Col>
                <SmallTechPattern position="right" />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={10}>
              <h3 className="text-primary mb-5">
                {t("jobs.careers.text3.bullet1")}
                <br />
                {t("jobs.careers.text3.bullet2")}
                <br />
                {t("jobs.careers.text3.bullet3")}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <h3 className="mb-5">{t("jobs.careers.text4")}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <h3 className="mb-5">{t("jobs.careers.text5")}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <h3 className="mb-5">{t("jobs.careers.text6")}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <h3 className="text-primary mb-5">{t("jobs.careers.text7")}</h3>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start align-items-end">
              <Button
                href="#careers"
                buttonType="secondary"
                click={backClickHandler}
              >
                <p>{t("jobs.return.button")}</p>
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <HexaLink
                text={
                  <p>
                    {t("jobs.careers.hexa1")} <br /> {t("jobs.careers.hexa2")}
                  </p>
                }
                href="#apply"
                click={() => setShowApply(true)}
              />
            </Col>
          </Row>
        </>
      </Section>
      <Separator type="primary" />
      {showApply && (
        <Section id="apply">
          <>
            <Row className="position-relative">
              {!isMobile && (
                <Col>
                  <SmallTechPattern position="farLeft" />
                </Col>
              )}
              <Col sm={9} className="mx-auto">
                <Form t={t} isMobile={isMobile} />
              </Col>
              <Col></Col>
            </Row>
          </>
        </Section>
      )}
      <ScrollTopArrow click={scrollTop} scroll={offsetY} />
    </main>
  );
};

export default Searches;
