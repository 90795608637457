import React, { MouseEventHandler } from "react";

import styles from "./Button.module.scss";

type Props = {
  children: React.ReactElement;
  className?: string;
  buttonType: "primary" | "secondary";
  href?: string;
  click?: MouseEventHandler;
  scroll?: number;
  width?: number | null;
  isMobile?: boolean;
  finishedIntro?:boolean;
};

const Button = ({
  children,
  className,
  buttonType,
  href,
  click,
  scroll,
  width,
  isMobile,
  finishedIntro
}: Props) => {
  const { Button, Hide } = styles;

  const Content = () => {
    if (href) {
      return (
        <a
          style={
            width ? { width: `${width}rem`, textAlign: "center" } : undefined
          }
          onClick={click}
          href={href}
          className={`${Button} ${className ?? null} ${styles[buttonType]} ${
            finishedIntro && !isMobile && scroll && scroll > window.innerHeight - 100
              ? Hide
              : null
          }`}
        >
          {children}
        </a>
      );
    } else {
      return (
        <button
          type="button"
          className={`${Button} ${className ?? null} ${styles[buttonType]} ${
            finishedIntro && scroll && scroll > window.innerHeight - 100 ? Hide : null
          }`}
        >
          {children}
        </button>
      );
    }
  };

  return <Content />;
};

export default Button;
