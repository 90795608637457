import { ForwardedRef, forwardRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechPattern from "../ui/TechPattern/TechPattern";

import style from "./Section.module.scss";

interface Props {
  children: React.ReactElement;
  className?: string;
  bg?: "dark";
  topLeftPattern?: boolean;
  id?: string;
}

const Section = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { children, className, bg, topLeftPattern, id } = props;

  const { Section, DarkBg } = style;

  return (
    <Container
      id={id ?? ""}
      ref={ref}
      fluid
      className={`${Section} ${className ?? ""} ${
        bg ? DarkBg : "bg-light"
      } position-relative section`}
    >
      <Container>
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
      {topLeftPattern && <TechPattern position="topLeft" opacity="half" />}
    </Container>
  );
});

export default Section;
