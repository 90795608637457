import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TechPattern } from "../ui/TechPattern/TechPattern";
import Lottie from "react-lottie";
import gsap from "gsap";

import * as intro from "../../assets/lottie/intro.json";

import fullLogoWhite from "../../assets/svg/full-logo-white.svg";

import style from "./Section.module.scss";

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys: any = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: any) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: any) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: intro,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface Props {
  children?: React.ReactElement;
  introAnimation?: boolean;
  id?: string;
  runAnimation?: boolean;
  setRunAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
  bottomLeftLogo?: boolean;
  onCompleteScrollIntro?: () => void;
  offsetY?: number;
}

const Section = ({
  children,
  introAnimation,
  id,
  runAnimation,
  setRunAnimation,
  bottomLeftLogo,
  onCompleteScrollIntro,
  offsetY
}: Props) => {
  const sectionRef = useRef(null);
  const tl = gsap.timeline();
  const [scrolledAfterAnimation, setScrolledAfterAnimation] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [autoScroll, setAutoScroll] = useState(false)
  const [scrollTop, setScrollTop] = useState(false)
  const { SectionFullHeight, IntroSection, BottomLeftLogo } = style;

  const scrollToHome = () => {
    window.scrollTo(0, window.innerHeight);
  };

  useEffect(() => {
    if(offsetY !== undefined && offsetY >= window.innerHeight + 1){
      setAutoScroll(false);
      setScrolledAfterAnimation(true);
      if(window.innerWidth >= 768 && setRunAnimation){
        setRunAnimation(false);
      }
      // setRunAnimation && setRunAnimation(false);
      onCompleteScrollIntro && onCompleteScrollIntro();
    }else{
      setAutoScroll(true);
    }
  }, [offsetY])

  useEffect(() => {
    if (animationCompleted && !scrolledAfterAnimation) {
      setScrolledAfterAnimation(true);

      if(autoScroll){
        scrollToHome();
      }
    }
  }, [scrolledAfterAnimation, animationCompleted, autoScroll]);

  const onCompleteAnimation = () => {
    setAnimationCompleted(true);
    
    setTimeout(() => {
      if(window.innerWidth >= 768 && setRunAnimation){
        setRunAnimation(false);
      }
      onCompleteScrollIntro && onCompleteScrollIntro();
    }, 800);

  };

  useEffect(() => {
    return () => {
      tl.kill();
      // document.documentElement.scrollTop = window.innerHeight - window.pageYOffset;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (introAnimation && runAnimation && !scrollTop) {
      window.scrollTo(0, 0);
      setScrollTop(true)
    }
  }, [introAnimation, runAnimation, scrollTop]);


  return (
    <Container
      // onClick={clickHandler}
      id={id ?? ""}
      ref={sectionRef}
      fluid
      className={`${SectionFullHeight} ${
        introAnimation ? IntroSection : ""
      } bg-primary text-light section`}
    >
      <Container>
        <Row>
          <Col>
            {introAnimation && runAnimation ? (
              <Lottie
                isClickToPauseDisabled={true}
                options={defaultOptions}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: onCompleteAnimation,
                  },
                ]}
              />
            ) : (
              children
            )}
            {bottomLeftLogo && (
              <img className={BottomLeftLogo} src={fullLogoWhite} alt="Logo" />
            )}
          </Col>
        </Row>
      </Container>
      <TechPattern position="bottomRight" />
      <TechPattern position="topLeft" />
    </Container>
  );
};

export default Section;
