import React from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../interfaces/types";
import styles from "./LanguageSelector.module.scss";

interface Props {
  theme: Theme;
  scroll?: number;
  isMobile?: boolean;
  finishedIntro?: boolean;
}

const lngs = {
  es: { name: "ES" },
  en: { name: "EN" },
  pt: { name: "PT" },
};

const LanguageSelector = ({
  theme,
  scroll,
  isMobile,
  finishedIntro,
}: Props) => {
  const { i18n } = useTranslation();
  const selectedLang = i18n.resolvedLanguage;
  const { LanguageSelector, Active, LightTheme, DarkTheme, Hide } = styles;
  let themeStyle = "";

  const clickLanguageHandler = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  if (theme === "dark") {
    themeStyle = LightTheme;
  } else {
    themeStyle = DarkTheme;
  }

  return (
    <div
      className={`${LanguageSelector} ${themeStyle} ${
        finishedIntro &&
        !isMobile &&
        scroll &&
        scroll > window.innerHeight - 100
          ? Hide
          : null
      }`}
    >
      {Object.keys(lngs).map((lng, index) => (
        <React.Fragment key={lng}>
          <p
            className={`${lng === selectedLang ? Active : ""} dont-animate`}
            onClick={() => clickLanguageHandler(lng)}
          >
            {(lngs as any)[lng].name}
          </p>
          {Object.keys(lngs).length - 1 !== index && <span></span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LanguageSelector;
