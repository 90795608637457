import {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useRef,
  useState,
  useEffect,
} from "react";
import HexaLink from "../ui/HexaLink/HexaLink";
import styles from "./Form.module.scss";
import { Translation } from "../../interfaces/types";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import closeBlack from "../../assets/svg/close-black.svg";
import axios from "axios";

const emailRegEx = new RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const tenBytes = 10485760;

const supportedFormats = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "xlsx",
  "xls",
  "xml",
  "doc",
  "docx",
  "txt",
  "html",
];

type SimpleInputValidation = (value: string) => boolean;

type FileInputValidation = (value: File) => boolean;

type Props = {
  t: Translation;
  isMobile: boolean;
};

const Form = ({ t, isMobile }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { Form, InputContainer, FormError, Success } = styles;

  useEffect(() => {
    if (fileError || emailError) {
      setFormError(null);
    }
  }, [fileError, emailError]);

  const postFormData = async (
    email: string,
    filename: string,
    downloadUrl: string
  ) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact`, {
        email,
        filename,
        downloadUrl,
      });
      setLoading(false);
      setFormError(null);
      setFile(undefined);
      if (fileInput && fileInput.current) {
        fileInput.current.value = "";
      }
      if (emailInput && emailInput.current) {
        emailInput.current.value = "";
      }
      setSuccessMessage(t("jobs.form.success"));
    } catch (error) {
      setFormError(t("jobs.form.formError1"));
      setLoading(false);
    }
  };

  const attachFileHandler: MouseEventHandler = () => {
    fileInput?.current?.click();
    setFormError(null);
    setSuccessMessage(null);
  };

  const fileChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];

      setFile(file);
      validFile(file);
    }
  };

  const validEmail: SimpleInputValidation = (email) => {
    return emailRegEx.test(email);
  };

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setFormError(null);
    setSuccessMessage(null);
    if (validEmail(e.target.value)) {
      setEmailError(null);
    } else {
      setEmailError(t("jobs.form.emailError1"));
      setSuccessMessage(null);
    }
  };

  const validFile: FileInputValidation = (inputFile) => {
    if (inputFile.size > tenBytes) {
      setFileError(t("jobs.form.fileError1"));
      setSuccessMessage(null);
      return false;
    }

    if (!supportedFormats.some((format) => inputFile.type.includes(format))) {
      setFileError(t("jobs.form.fileError2"));
      setSuccessMessage(null);
      return false;
    }

    setFileError(null);
    return true;
  };

  const submitHandler: FormEventHandler = (e) => {
    e.preventDefault();
    let errors = [];

    if (!emailInput?.current?.value) {
      setEmailError(t("jobs.form.emailError2"));
      setSuccessMessage(null);
    }

    if (!file) {
      setFileError(t("jobs.form.fileError3"));
      setSuccessMessage(null);
    }

    if (errors.length > 0) return;

    const email = emailInput?.current?.value;
    if (!fileError && !emailError && file && email && !loading) {
      setFormError(null);
      const filename = file.name;

      const fileRef = ref(storage, filename);

      setLoading(true);
      uploadBytes(fileRef, file)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((donwloadUrl) => {
              postFormData(email, filename, donwloadUrl);
            })
            .catch((err) => {
              setLoading(false);
              setFormError(t("jobs.form.formError2"));
            });
        })
        .catch((err) => {
          setLoading(false);
          setFormError(t("jobs.form.formError3"));
        });
    }
  };

  const deleteFileHandler = () => {
    setFile(undefined);
    if (fileInput && fileInput.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <form className={Form} onSubmit={submitHandler}>
      <Row className={InputContainer}>
        <Col sm={11} xs={11}>
          <input
            ref={emailInput}
            placeholder={t("jobs.form.email")}
            type="email"
            name="email"
            id="email"
            onBlur={handleEmailBlur}
          />
        </Col>
        <Col sm={1} xs={1}></Col>
        <p>{emailError}</p>
      </Row>
      <Row className={InputContainer}>
        <Col sm={11} xs={11}>
          <input
            placeholder={t("jobs.form.cv")}
            type="text"
            name="customFile"
            id="customFile"
            onClick={attachFileHandler}
            value={file ? file?.name : ""}
            readOnly
          />
        </Col>
        <Col
          onClick={deleteFileHandler}
          className="d-flex justify-content-center align-items-center"
          sm={1}
          xs={1}
        >
          {file && <img src={closeBlack} alt="close" />}
        </Col>
        <p>{fileError}</p>
      </Row>
      <input
        onChange={fileChangeHandler}
        ref={fileInput}
        type="file"
        name="file"
        id="file"
        hidden
      />
      <HexaLink
        loading={loading}
        text={<p>{t("jobs.form.send")}</p>}
        submitButton={true}
      />
      <p className={`${FormError} mt-5`}>{formError}</p>
      <p className={`${Success} mt-5`}>{successMessage}</p>
    </form>
  );
};

export default Form;
