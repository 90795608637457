import React, { MouseEventHandler, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import tech from "../../../assets/svg/tech-single.svg";
import style from "./HexaLink.module.scss";

interface Props {
  text: ReactElement;
  href?: string;
  link?: string;
  submitButton?: boolean;
  click?: MouseEventHandler;
  loading?: boolean;
}

interface Wrapper {
  children: ReactNode;
}

const HexaLink = React.memo(
  ({ text, href, link, submitButton, click, loading }: Props) => {
    const { Anchor, HexaLink, Button, Loading } = style;

    const Wrapper = ({ children }: Wrapper) => {
      if (href) {
        return (
          <a href={href} className={Anchor} onClick={click}>
            {children}
          </a>
        );
      } else if (link) {
        return (
          <Link to={link} className={Anchor}>
            {children}
          </Link>
        );
      } else if (submitButton) {
        return (
          <button type="submit" className={`${Anchor} ${Button}`}>
            {children}
          </button>
        );
      }

      return null;
    };

    const Content = () => {
      return (
        <div className={`${HexaLink} ${loading ? Loading : null}`}>
          {text}
          <svg
            id="Hexa"
            data-name="Hexa"
            xmlns="http://www.w3.org/2000/svg"
            width="189.6596"
            height="219.0001"
            viewBox="0 0 189.6596 219.0001"
          >
            <polygon
              points="189.66 164.25 189.66 54.75 94.83 0 0 54.75 0 164.25 94.83 219 189.66 164.25"
              fill="#1fb198"
            />
          </svg>
          <img src={tech} alt="Tech pattern" />
        </div>
      );
    };

    return (
      <Wrapper>
        <Content />
      </Wrapper>
    );
  }
);

export default HexaLink;
