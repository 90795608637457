import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import "./assets/scss/global.scss";

// import i18n
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Suspense fallback="">
    <App />
  </Suspense>
);
