import { useState, useLayoutEffect, useRef, useEffect } from "react";
import gsap from "gsap";
import styles from "./HoverTitle.module.scss";

type Props = {
  title: string;
  text: string;
  isMobile?: boolean;
};

const HoverTitle = ({ title, text, isMobile }: Props) => {
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const textRef = useRef(null);
  const { HoverTitle } = styles;

  useEffect(() => {
    // gsap.set(textRef.current, { yPercent: "-110" });
    setLoaded(true);
  }, []);

  useLayoutEffect(() => {
    if (loaded) {
      if (!isMobile) {
        if (show) {
          gsap.to(textRef.current, {
            // yPercent: "0",
            duration: 0.5,
            ease: "power2.out",
            opacity: 1,
          });
        } else {
          gsap.to(textRef.current, {
            // yPercent: "-110",
            duration: 0.5,
            opacity: 0,
            ease: "power2.out",
          });
        }
      }
    }
  }, [show]);

  // const clickHandler = () => {
  //   setShow((prevState) => !prevState);
  // };

  const mouseEnterHandler = () => {
    setShow(true);
  };

  const mouseLeaveHandler = () => {
    setShow(false);
  };

  return (
    <div
      className={HoverTitle}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <h2 className="dont-animate">{title}</h2>
      <p className="dont-animate" ref={textRef}>
        {text}
      </p>
    </div>
  );
};

export default HoverTitle;
