import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  MouseEventHandler,
} from "react";
import gsap from "gsap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
// import { ReactComponent as Twitter } from "../../assets/svg/twitter.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/linkedin.svg";
import close from "../../assets/svg/close.svg";
import { useNavigate } from "react-router-dom";
import {
  Translation,
  ToogleMenuRefFunction,
  Timeline,
  Theme,
} from "../../interfaces/types";
import LanguageSelector from "../ui/LanguageSelector/LanguageSelector";

import styles from "./Menu.module.scss";

interface Props {
  openMenu: ToogleMenuRefFunction;
  closeMenu: ToogleMenuRefFunction;
  t: Translation;
  theme: Theme;
  offsetY: number;
}

const Menu = ({ closeMenu, openMenu, t, theme, offsetY }: Props) => {
  const menuRef = useRef(null);
  const contentRef = useRef(null);
  const sectionsRef = useRef(null);
  const tl = useRef<Timeline>();
  const navigate = useNavigate();

  const {
    Menu,
    Content,
    Sections,
    Close,
    ContentContainer,
    Social,
    TopRight,
    MenuSocialContainer,
  } = styles;

  const navSections = [
    { title: t("menu.section1"), link: "#home" },
    { title: t("menu.section2"), link: "#us" },
    { title: t("menu.section3"), link: "#team" },
    { title: t("menu.section4"), link: "#what-we-do" },
    { title: t("menu.section5"), link: "#partners" },
    { title: t("menu.section6"), link: "#careers" },
    { title: t("menu.section7"), link: "#contact" },
  ];

  useEffect(() => {
    openMenu.current = openMenuAnimation;
    closeMenu.current = closeMenuAnimation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMenuAnimation = () => {
    return tl?.current?.play();
  };

  const closeMenuAnimation = () => {
    return tl?.current?.reverse(1.5);
  };

  useLayoutEffect(() => {
    tl.current = gsap
      .timeline({ paused: true })
      .to(menuRef.current, {
        autoAlpha: 1,
        duration: 0.01,
      })
      .to(menuRef.current, {
        scale: 60,
        ease: "power3.out",
        duration: 2.5,
      })
      .to(
        contentRef.current,
        {
          autoAlpha: 1,
        },
        "-=2"
      )
      .fromTo(
        sectionsRef.current,
        { yPercent: 30, autoAlpha: 0 },
        { yPercent: 0, duration: 0.5, autoAlpha: 1 },
        "-=1.5"
      );

    gsap.set(contentRef.current, {
      autoAlpha: 0,
    });
    gsap.set(menuRef.current, {
      autoAlpha: 0,
    });
    gsap.set(sectionsRef.current, {
      autoAlpha: 0,
    });
  }, []);

  const clickLinkHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
    closeMenuAnimation();
    const href = e.currentTarget.href.split("#")[1];

    navigate(`/#${href}`);
  };

  return (
    <>
      <div ref={menuRef} className={Menu} onClick={closeMenuAnimation}></div>
      <Container fluid ref={contentRef} className={Content}>
        <Container className={ContentContainer}>
          <div className={TopRight}>
            <LanguageSelector theme={theme} scroll={offsetY} />
            <img
              src={close}
              alt="Close"
              className={Close}
              onClick={closeMenuAnimation}
            />
          </div>
          <div className={MenuSocialContainer}>
            <Col ref={sectionsRef} className={Sections}>
              {navSections.map(({ link, title }, index) => {
                return (
                  <a
                    className={`${
                      index === navSections.length - 1 ? "mb-0" : ""
                    }`}
                    key={title}
                    href={link}
                    onClick={clickLinkHandler}
                  >
                    {title}
                  </a>
                );
              })}
            </Col>
            <div className={Social}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/almaglobalstrategies/?viewAsMember=true"
              >
                <Linkedin />
              </a>
              {/* <Twitter /> */}
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Menu;
