import React, { MouseEventHandler } from "react";
import arrow from "../../../assets/svg/arrow.svg";

import styles from "./ScrollTopArrow.module.scss";

interface Props {
  scroll: number;
  click: MouseEventHandler;
}

const ScrollTopArrow = ({ scroll, click }: Props) => {
  const arrowStyles = [styles.Arrow];

  if(window.innerWidth < 768 && scroll > window.innerHeight + 200){
    arrowStyles.push(styles.Show);
  }

  if(window.innerWidth >= 768 && scroll > 200){
    arrowStyles.push(styles.Show);
  }

  return (
    <aside onClick={click} className={arrowStyles.join(" ")}>
      <img src={arrow} alt="Scroll Top" />
    </aside>
  );
};

export default ScrollTopArrow;
