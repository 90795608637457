import { MouseEventHandler } from "react";
import Button from "../ui/Button/Button";
import logoWhite from "../../assets/svg/logo-white.svg";
import logoColor from "../../assets/svg/logo-color.svg";
import { Theme, Translation } from "../../interfaces/types";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../ui/LanguageSelector/LanguageSelector";

import styles from "./Navigation.module.scss";

interface Props {
  showMenu: () => void;
  theme: Theme;
  t: Translation;
  offsetY: number;
  width: number | undefined;
  finishedIntro: boolean;
}

const Navigation = ({
  showMenu,
  theme,
  t,
  offsetY,
  width,
  finishedIntro,
}: Props) => {
  const navigate = useNavigate();
  const { Navigation, MarginRight, HoverLogo } = styles;

  const isMobile = !!width && width < 768;

  const clickLinkHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
    const href = e.currentTarget.href.split("#")[1];

    navigate(`/#${href}`);
  };

  return (
    <nav className={Navigation}>
      <LanguageSelector
        finishedIntro={finishedIntro}
        isMobile={isMobile}
        theme={isMobile ? "dark" : theme}
        scroll={offsetY}
      />
      <Button
      finishedIntro={finishedIntro}
        click={clickLinkHandler}
        isMobile={isMobile}
        width={!isMobile ? 11 : 13}
        scroll={offsetY}
        href="#careers"
        buttonType={
          isMobile ? "secondary" : theme === "light" ? "primary" : "secondary"
        }
        className={MarginRight}
      >
        <p className="dont-animate">{t("navigation.careers")}</p>
      </Button>
      {!isMobile && theme === "light" && (
        <img
          className={HoverLogo}
          onClick={showMenu}
          src={logoWhite}
          alt="Alma Global"
        />
      )}
      {(isMobile || theme === "dark") && (
        <img
          className={HoverLogo}
          onClick={showMenu}
          src={logoColor}
          alt="Alma Global"
        />
      )}
      {/* <img onClick={showMenu} src={logoColor} alt="Alma Global" /> */}
    </nav>
  );
};

export default Navigation;
