import React from "react";
import separator from "../../../assets/img/separator.png";
import separator2 from "../../../assets/img/separator2.png";

type Props = {
  type: "primary" | "secondary";
};

const Separator = ({ type }: Props) => {
  let image = separator;

  if (type === "secondary") {
    image = separator2;
  }

  return (
    <img
      className={`w-100 mt-5 section ${type}`}
      src={image}
      alt="Separator"
    ></img>
  );
};

export default Separator;
