import { useLayoutEffect, useState } from "react";
import { gsap } from "gsap";

const useTextAnimations = (runAnimations: boolean) => {
  const [animations, setAnimations] = useState<GSAPTween[]>();

  useLayoutEffect(() => {
    let animationsArray: GSAPTween[] = [];

    runAnimations &&
      gsap.utils
        .toArray<HTMLElement>([
          "h1:not(.dont-animate)",
          "h2:not(.dont-animate)",
          "h3:not(.dont-animate)",
          "h4:not(.dont-animate)",
          "h5:not(.dont-animate)",
          "p:not(.dont-animate)",
        ])
        .forEach((selector) => {
          let animation = gsap.from(selector, {
            y: 50,
            opacity: 0,
            scrollTrigger: {
              trigger: selector,
              start: "center bottom",
              toggleActions: "play none play reverse",
            },
          });

          animationsArray.push(animation);
        });

    setAnimations(animationsArray);

    return () => {
      animations?.forEach((animation) => {
        animation.kill();
      });
    };
  }, [runAnimations]);

  return animations;
};

export default useTextAnimations;
