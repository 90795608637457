import { useEffect, useState, useRef, useSyncExternalStore } from "react";
import SectionFullHeight from "./components/Section/SectionFullHeight";
import Section from "./components/Section/Section";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SmallTechPattern } from "./components/ui/TechPattern/TechPattern";
import HexaLink from "./components/ui/HexaLink/HexaLink";
import Separator from "./components/ui/Separator/Separator";
import TeamGallery from "./components/TeamGallery/TeamGallery";
import HoverAlmaTitles from "./components/HoverAlmaTitles/HoverAlmaTitles";
import allaria from "./assets/img/allaria.png";
import bolsaBr from "./assets/img/bolsa-br.png";
import byma from "./assets/img/byma.png";
import bradesco from "./assets/img/bradesco.png";
import { OrganizationFlipCard } from "./components/ui/FlipCard/FlipCard";
import { ReactComponent as Linkedin } from "./assets/svg/linkedin.svg";
import useObserver from "./hooks/useObserver";
import { changeNavTheme } from "./helpers";
import { Translation, Theme } from "./interfaces/types";
import ScrollTopArrow from "./components/ui/ScrollTopArrow/ScrollTopArrow";
import { scrollTop } from "./helpers/index";
import Button from "./components/ui/Button/Button";
import LanguageSelector from "./components/ui/LanguageSelector/LanguageSelector";
import useTextAnimations from "./hooks/useTextAnimations";

import logoWhite from "./assets/svg/logo-white.svg";

interface Props {
  gsap: GSAP;
  t: Translation;
  width: number | undefined;
  setNavTheme: (value: React.SetStateAction<Theme>) => void;
  runAnimation: boolean;
  setRunAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  offsetY: number;
  finishedIntro?: () => void;
}

const Home = ({
  gsap,
  t,
  width,
  setNavTheme,
  runAnimation,
  setRunAnimation,
  offsetY,
  finishedIntro,
}: Props) => {
  const [textAnimations, setTextAnimations] = useState(false);
  const [observer, setElements, entries] = useObserver();
  const [showArrow, setShowArrow] = useState(false)
  useTextAnimations(textAnimations);

  useEffect(() => {
    if (Array.isArray(entries)) changeNavTheme(entries, setNavTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries, observer]);

  useEffect(() => {
    if (typeof setElements === "function") {
      const sections = document.getElementsByClassName("section");
      setElements(Array.from(sections));
    }
  }, [setElements]);

  const isMobile = !!width && width < 768;

  const onCompleteScrollIntroHandler = () => {
    setTextAnimations(true);
    setTimeout(() => {
      setShowArrow(true)
    }, 1000);
    finishedIntro && finishedIntro();
  };

  const scrollTopArrowClickHandler = () => {
    if(window.innerWidth < 768 && offsetY > window.innerHeight + 200){
      scrollTop(window.innerHeight)
    }
  
    if(window.innerWidth >= 768 && offsetY > 200){
      scrollTop()
    }
  }

  return (
    <main>
      {runAnimation && (
        <SectionFullHeight
          offsetY={offsetY}
          introAnimation
          runAnimation={runAnimation}
          setRunAnimation={setRunAnimation}
          onCompleteScrollIntro={onCompleteScrollIntroHandler}
        />
      )}
      <SectionFullHeight id="home" bottomLeftLogo>
        <h1 className="dont-animate">{t("home.home.title")}</h1>
      </SectionFullHeight>
      <Section id="us">
        <>
          <h1>{t("home.us.title")}</h1>
          <Col sm={10}>
            <h2>{t("home.us.text1")}</h2>
          </Col>
          <Row className="position-relative">
            {!isMobile && (
              <Col>
                <SmallTechPattern position="left" />
              </Col>
            )}
            <Col className="text-end ms-auto" sm={10}>
              <h3>{t("home.us.text2")}</h3>
            </Col>
          </Row>
          <Row className="position-relative">
            <Col sm={10}>
              <h2 className="r3-font">
                {t("home.us.text3.t1")}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.b3.com.br/"
                >
                  {t("home.us.text3.a1")}
                </a>
                {t("home.us.text3.t2")}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.byma.com.ar/"
                >
                  {t("home.us.text3.a2")}
                </a>
                {t("home.us.text3.t3")}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.allaria.com.ar/"
                >
                  {t("home.us.text3.a3")}
                </a>
                {t("home.us.text3.t4")}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://banco.bradesco/"
                >
                  {t("home.us.text3.a4")}
                </a>
                {t("home.us.text3.t5")}
              </h2>
            </Col>
            {!isMobile && (
              <Col>
                <SmallTechPattern position="right" />
              </Col>
            )}
          </Row>
          <Row>
            <Col className="text-end ms-auto" sm={8}>
              <h3 className="text-primary r37-font">{t("home.us.text4")}</h3>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <HexaLink
                text={
                  <p>
                    {t("home.us.hexa1")} <br /> {t("home.us.hexa2")}
                  </p>
                }
                href="#contact"
              />
            </Col>
            <Col></Col>
          </Row>
        </>
      </Section>
      <Separator type="primary" />
      <Section id="team">
        <>
          <h1>{t("home.team.title")}</h1>
          <Col sm={10}>
            <h2>{t("home.team.text1")}</h2>
          </Col>
          <Row className="position-relative">
            {!isMobile && (
              <Col>
                <SmallTechPattern position="left" />
              </Col>
            )}

            <Col className="text-end ms-auto" sm={10}>
              <h3>{t("home.team.text2")}</h3>
            </Col>
          </Row>
          {/* <img src={logoColor} alt="Alma Global" /> */}
          <TeamGallery t={t} gsap={gsap} width={width} />
        </>
      </Section>
      <Separator type="secondary" />
      <Section id="what-we-do">
        <>
          <h1>{t("home.what-we-do.title")}</h1>
          <Row>
            <Col sm={11}>
              <h2 className="mb-5 nunito-font fw-semibold">
                {t("home.what-we-do.text1")}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <h2>{t("home.what-we-do.text2")}</h2>
            </Col>
          </Row>
          <Row className="position-relative">
            {!isMobile && (
              <Col>
                <SmallTechPattern position="left" />
              </Col>
            )}

            <Col className="text-end ms-auto" sm={10}>
              <h3 className="mb-0">{t("home.what-we-do.text3")}</h3>
            </Col>
          </Row>
          <HoverAlmaTitles t={t} width={width} />
          <Row>
            <Col>
              <h4 className="text-primary">{t("home.what-we-do.text4")}</h4>
            </Col>
          </Row>
        </>
      </Section>
      <Separator type="secondary" />
      <Section id="partners" className="position-relative pb-0">
        <>
          <h1>
            {t("home.partners.title1")} <br /> {t("home.partners.title2")}
          </h1>
          {!isMobile && (
            <Col>
              <SmallTechPattern position="leftCenter" />
            </Col>
          )}
          <Row>
            <Col sm={true}>
              <OrganizationFlipCard cardImage={allaria} isMobile={isMobile}>
                <>{t("home.partners.card1")}</>
              </OrganizationFlipCard>
            </Col>
            <Col sm={true}>
              <OrganizationFlipCard cardImage={bradesco} isMobile={isMobile}>
                <>{t("home.partners.card2")}</>
              </OrganizationFlipCard>
            </Col>
          </Row>
          <Row>
            <Col sm={true}>
              <OrganizationFlipCard cardImage={byma} isMobile={isMobile}>
                <>{t("home.partners.card3")}</>
              </OrganizationFlipCard>
            </Col>
            <Col sm={true}>
              <OrganizationFlipCard cardImage={bolsaBr} isMobile={isMobile}>
                <>{t("home.partners.card4")}</>
              </OrganizationFlipCard>
            </Col>
          </Row>
        </>
      </Section>
      <Separator type="primary" />
      <Section id="careers">
        <>
          <h1>{t("home.careers.title1")}</h1>
          <Row className="position-relative">
            <Col sm={10}>
              <h3>{t("home.careers.text1")}</h3>
            </Col>
            {!isMobile && (
              <Col>
                <SmallTechPattern position="right" />
              </Col>
            )}
          </Row>
          <h1 className="text-end">{t("home.careers.title2")}</h1>
          <Row>
            <Col sm={8} className="text-end ms-auto">
              <h2 className="mb-5">{t("home.careers.text2")}</h2>
              <h5 className="text-primary mb-5">
                {t("home.careers.text3.a")} <br /> {t("home.careers.text3.b")}{" "}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <HexaLink
                text={
                  <p>
                    {t("home.careers.hexa1")} <br /> {t("home.careers.hexa2")}{" "}
                    <br /> {t("home.careers.hexa3")}
                  </p>
                }
                link="jobs"
              />
            </Col>
            <Col></Col>
          </Row>
        </>
      </Section>
      <Section id="contact" bg="dark" topLeftPattern={true}>
        <div className="d-flex flex-row justify-content-between flex-wrap">
          <div>
            {!isMobile ? (
              <h1>{t("home.contact.title")}</h1>
            ) : (
              <div className="d-flex flex-row justify-content-between align-items-center mb-5">
                <h1 className="mb-0">{t("home.contact.title")}</h1>
                <LanguageSelector theme="dark" />
              </div>
            )}
            <h4 className="text-light mb-4 dont-animate">
              contact@almaglobalstrategies.com
            </h4>
            <h4 className="text-light mb-4 dont-animate">+59829272261</h4>
            <h4 className="text-light mb-4 dont-animate">
              Dr. Luis Bonavita 1294, WTC Free Zone <br /> Torre 2 Oficina 1238,
              Uruguay
            </h4>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-end">
            {!isMobile && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <LanguageSelector theme="dark" />
                <Button
                  width={!isMobile ? 11 : null}
                  href="#careers"
                  buttonType="secondary"
                  className="cmr-5"
                >
                  <p>{t("navigation.careers")}</p>
                </Button>
                <img
                  src={logoWhite}
                  alt="Logo"
                  style={{ width: "80px" }}
                  // onClick={scrollTop}
                />
              </div>
            )}

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/almaglobalstrategies/?viewAsMember=true"
            >
              <Linkedin className="linkedin-svg" />
            </a>
          </div>
        </div>
      </Section>
     {showArrow && <ScrollTopArrow click={scrollTopArrowClickHandler} scroll={offsetY} />} 
    </main>
  );
};

export default Home;
